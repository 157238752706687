import React, { useState, useEffect } from 'react';
import { Layout, Typography, Flex, Space, Row, Col, Button } from 'antd';
import logo from '../assets/images/favicon.png'
import backgroundImgsrc from '../assets/images/重阳节-黄山云海.jpeg';
// import backgroundImg from '../assets/images/地肤草.jpeg';
import NavBar from '../components/NavBar';
import Shaders from '../components/Shaders';
import axios from 'axios';
// import { useGlobalContext } from '../context/GlobalProvider';
import { useGlobalData } from '../context/GlobalProvider';


const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

function Home () {
  const [bingImages, setBingImages] = useState([]);
  // const { apiUrl } = useGlobalContext();
  const { globalData } = useGlobalData();
  const [backgroundImg, setBackgroundImg] = useState('');

  const fetchBingWallpaper = async () => {
    const url = process.env.NODE_ENV === 'development' ? '/api/images' : `${globalData.apiUrl}/images`;
    console.log(globalData.apiUrl);

    try {
      // axios.get('https://hk-cors.thehkus.com:8080/https://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=8&mkt=zh-CN')
      // axios.get('/api/HPImageArchive.aspx?format=js&idx=0&n=8&mkt=zh-CN')
      // axios.get('https://www.bing.com/HPImageArchive.aspx?format=js&idx=0&n=8&mkt=zh-CN')
      axios.get(url, {headers: {'x-api-key': globalData.apiKey}})
        .then(res => {
          // console.log(res.data);
          setBingImages(res.data.images);
          setBackgroundImg('https://www.bing.com' + res.data.images[0]?.url);
        })
        .catch(err => {
          setBackgroundImg(backgroundImgsrc);
          // console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchBingWallpaper();
  }, []);

  const mainTextStyle = {
    color: '#f8f8f8',
    // textShadow:'1px 1px 1px #333,0 0 1.5em #333,0 0 0.1em white',
    textShadow: '0.5px 0.5px 1px rgba(0, 0, 0, 0.5), 0px 0px 2px rgba(0, 0, 0, 0.2),0px 0px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  }

  return (
    <>
      <Shaders />
      <Layout
        style={{
          height: '100%',
          alignItems: 'center',
          // background: `url(${backgroundImg}) no-repeat center/cover `,
          backgroundImage: `url(${backgroundImg})`,
          backgroundColor: '#333',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>

        <Header style={{
          width: '100%',
          maxWidth: 1200,
          padding: '0 1rem',
          display: 'flex',
          alignItems: 'center',
          background: 'transparent',
          zIndex: 10,
        }}>
          <img src={logo} alt="THEHKUS LOGO" />
          <Title
            level={3}
            style={{
              margin: "0 0.6rem",
              textShadow: "3px 3px 4px rgba(0, 0, 0, .3)",
              color: '#FFF',
            }}>
            THEHKUS
          </Title>
          <NavBar />
        </Header>

        <Content>
          <Flex style={{
            padding: '0 1rem',
            width: '100%',
            maxWidth: 1200,
            height: '100%',
          }}
            justify='center'
            align='center'
            wrap
          >
            {
              bingImages.length > 0 &&
              <Col>
                <Space direction='vertical' size={'middle'} align='center'>
                  <Title level={3} style={mainTextStyle}>{bingImages[0]?.title}</Title>
                  {/* <Title level={2} style={mainTextStyle}>Welcome ‧ 欢迎</Title> */}
                  <Text strong style={mainTextStyle}>{bingImages[0]?.copyright}</Text>
                  {/* <Title level={5} style={mainTextStyle}>Miracles happen every day. 奇迹每天都在发生。</Title> */}
                  <Button color="default" ghost onClick={() => {
                    window.open(bingImages[0]?.copyrightlink, '_blank', 'noreferrer noopener');
                  }}>
                    探索背景图片
                  </Button>
                </Space>
              </Col>
            }
          </Flex>
        </Content>
        <Footer
          style={{
            color: '#F5F5F5',
            background: 'transparent',
            zIndex: 1,
          }}
        >
          © 2017-{new Date().getFullYear()} All Rights Reserved.&ensp;|&ensp;Image &copy; Bing Wallpaper
        </Footer>

      </Layout>
    </>
  );
}

export default Home;