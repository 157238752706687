import React from 'react';
import '../styles/nav.css'
import { Flex, Menu } from 'antd';

const NavBar = () => {


  const menuItems = [
    {
      key: '1',
      label: 'The Cloud',
      children: [
        { label: (<a href="https://cloud.thehkus.com">全球</a>), key: '1.1' },
        { label: (<a href="https://cn.cloud.thehkus.com:7443">中国内地</a>), key: '1.2' },
      ]
    },
    {
      key: '2',
      label: 'AnyConnect',
      children: [
        { label: (<a href="https://cn2hk.thehkus.com:30444" style={{ cursor: "not-allowed"}} onClick={(e) => e.preventDefault()}>中国香港</a>), key: '2.1', disabled: true, },
        { label: (<a href="https://cn2sg.thehkus.com:30444" >新加坡</a>), key: '2.2' },
        { label: (<a href="https://cn2usa2.thehkus.com" style={{ cursor: "not-allowed" }} onClick={(e) => e.preventDefault()}>美国</a>), key: '1.3', disabled: true, },
      ]
    },

    {
      key: '3',
      label: (<a href="https://status.thehkus.com">System Status</a>)

    },
  ];

  return (
    <Menu
      // theme="dark"
      mode="horizontal"
      items={menuItems}
      style={{
        minWidth: 0,
        flex: 1,
        justifyContent: 'flex-end',
        background: 'transparent',
      }}
    />
  );

}

export default NavBar;