import React from 'react';

const Shaders = () => {
  const shadersStyle = {
    // pointerEvents: 'none', // 允许点击穿透
    position: 'fixed',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, .1)',
  }

  const top = {
    height: '6%',
    position: 'absolute',
    width: '100%',
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .3) 100%)',
  }

  const topLeftStyle = {
    height: '28%',
    position: 'absolute',
    left: 0,
    width: '100%',
    background: 'linear-gradient(to bottom left, rgba(0, 0, 0, 0.5) -20%, transparent 30%)'
    // background: 'linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%)',
  }

  const topRightStyle = {
    height: '28%',
    position: 'absolute',
    left: 0,
    width: '100%',
    background: 'linear-gradient(to bottom right, rgba(0, 0, 0, 0.5) -20%, transparent 30%)'
  }

  const bottom = {
    height: '28%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%)',
  }


  return (
    <div style={shadersStyle}>
      <div style={top}></div>
      {/* <div style={topLeftStyle}></div>
      <div style={topRightStyle}></div> */}
      <div style={bottom}></div>
    </div>
  );
}

export default Shaders;