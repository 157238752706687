import React, { createContext, useContext, useState } from 'react';

// ============ Configuration setup Global Variables ============
const initGlobalDatas = {
  apiUrl: process.env.NODE_ENV === 'development'  
    ? process.env.REACT_APP_DEV_API_URL
    : process.env.REACT_APP_PROD_API_URL,
  apiKey: process.env.REACT_APP_API_KEY,
};

// Create Context
const GlobalContext = createContext();

// GlobalContext Provider component
export const GlobalProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState(initGlobalDatas);

  console.log(process.env.NODE_ENV);

  const updateGlobalData = (newData) => {
    setGlobalData(...globalData, ...newData);
  }

  return (
    <GlobalContext.Provider value={{ globalData, updateGlobalData }}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalData = () => {
  // Custom hook to use the ConfigContext
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalData must be used within a GlobalProvider");
  }
  return context;
};

/* --------- Usage in other Components ------------- 

import { useGlobalData, updateGlobalData } from "variables/context";
const { globalData } = useGlobalData();
console.log(JSON.stringify(globalData));
updateGlobalData({ map_center: [30.123, 40.567] });

*/